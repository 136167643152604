import React, { useState, useCallback } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Papa from 'papaparse';

const isEmptyArray = (array) => !Array.isArray(array) || !array.length;

function map2rows(map) {
  const matrix = Array.from(map.values());
  if (isEmptyArray(matrix)) {
    return [[]]
  }
  return matrix[0].map((col, i) => matrix.map(row => row[i]));
}

function toArrayOfMaps(map) {
  const headers = Array.from(map.keys());
  if (isEmptyArray(headers)) {
    return []
  }
  const flattened = map2rows(map);
  return flattened.map(row => new Map(row.map((cell, i) => [headers[i], cell])));
}

function toConsolidatedMap(arrayOfMaps) {
  if (isEmptyArray(arrayOfMaps)) {
    return new Map()
  }
  const headers = Array.from(arrayOfMaps[0].keys());
  const arrayOfValues = headers.map(h => [h, arrayOfMaps.map((m) => m.get(h))]);
  return new Map(arrayOfValues);
}

export default function SanitizeCSV(props) {
  const [data, setData] = useState(new Map().set("ID", ["1", "2"]).set("Full Name", ["Phil Winder", "Luke Marsden"]));
  const [isSending, setIsSending] = useState(false)
  const [selectedFile, setSelectedFile] = useState({ name: "" });
  const [filterInput, setFilterInput] = useState("");

  const fileHandler = (event) => {
    console.log(event.target.files[0]);
    setSelectedFile(event.target.files[0]);
    Papa.parse(event.target.files[0], {
      complete: (results) => {
        console.log("Finished:", results.data);
        setData(new Map(results.meta.fields.map((f) => [f, results.data.map(d => d[f])])));
      },
      header: true
    });
  }

  const saveFile = async (blob) => {
    const a = document.createElement('a');
    a.download = "cleaned.csv";
    a.href = URL.createObjectURL(blob);
    a.addEventListener('click', (e) => {
      setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    });
    a.click();
  };

  const downloadHandler = (event) => {
    console.log("Converting to CSV file.");
    var csv = Papa.unparse({
      "fields": Array.from(data.keys()),
      "data": map2rows(data),
    });
    const blob = new Blob([csv], { type: 'text/csv' });
    saveFile(blob);
  }

  const splitName = useCallback(async (key) => {
    console.log("Splitting name for column:" + key);
    // don't send again while we are sending
    if (isSending) return
    // update state
    setIsSending(true)
    // Gather data from internal structure to match API
    const values = data.get(key);
    const jsonObject = values.map((x) => ({ text: x }));
    // Construct options to match API
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "instances": jsonObject })
    };
    console.log(requestOptions)
    // Fire request
    await fetch("/v1/models/model:predict", requestOptions)
      .then(function (response) {
        if (response.ok) {
          return response.json();
        }
        throw new Error(response.status + " " + response.statusText);
      })
      .then((result) => {
        console.log(result);
        // Build internal structure from response
        const firstNameMap = new Map().set("First Name", result.predictions.map((x) => x.first_name));
        const familyNamesMap = new Map().set("Family Names", result.predictions.map((x) => x.family_names));
        setData(new Map([...data, ...firstNameMap, ...familyNamesMap]));
        setIsSending(false);
      }).catch((error) => {
        console.log(error)
        toast.error("Error splitting names: " + error);
        setIsSending(false);
      })
  }, [isSending, data]) // update the callback if the state changes

  const cleanName = useCallback(async (key) => {
    console.log("Cleaning First Name column:" + key);
    // don't send again while we are sending
    if (isSending) return
    // update state
    setIsSending(true)
    // Gather data from internal structure to match API
    const values = data.get(key);
    const jsonObject = values.map((x) => ({ text: x }));
    // Construct options to match API
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "instances": jsonObject })
    };
    console.log(requestOptions)
    // Fire request
    await fetch("/v1/models/model:predict", requestOptions)
      .then(function (response) {
        if (response.ok) {
          return response.json();
        }
        throw new Error(response.status + " " + response.statusText);
      })
      .then((result) => {
        console.log(result);
        // Build internal structure from response
        const cleanNameMap = new Map().set(key, result.predictions.map((x) => x.first_name + (x.family_names ? " " + x.family_names : "")));
        setData(new Map([...data, ...cleanNameMap]));
        setIsSending(false);
      }).catch((error) => {
        console.log(error)
        toast.error("Error splitting names: " + error);
        setIsSending(false);
      })
  }, [isSending, data]) // update the callback if the state changes

  const filterRows = useCallback((e, column) => {
    if (e.key !== "Enter") {
      return
    }
    console.log("filterRows", e, filterInput, column);
    const keys = Array.from(data.keys());
    const values = toArrayOfMaps(data);
    console.log(keys, values);

    const filteredValues = values.filter((row) => !row.get(column) || !row.get(column).includes(filterInput));
    console.log("filteredValues", filteredValues);
    if (isEmptyArray(filteredValues)) {
      toast.error("You've filtered out all data! Please start again.");
    }

    const result = toConsolidatedMap(filteredValues);
    console.log("result", result);
    setData(result);
  }, [filterInput, data]);

  return (
    <div id="sanitize-csv-app">
      <Toaster />
      <div class="content">
        <div class="columns is-centered">
          <div class="column is-one-quarter">
            <div class="file has-name">
              <label class="file-label">
                <input class="file-input" type="file" name="file" accept=".csv" onChange={fileHandler} />
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fas fa-upload"></i>
                  </span>
                  <span class="file-label">
                    Choose a file…
                  </span>
                </span>
                <span class="file-name">
                  {selectedFile.name}
                </span>
              </label>
            </div>
          </div>
          <div class="column is-one-quarter">
            <button class="button is-light" onClick={downloadHandler}>
              <span class="file-icon">
                <i class="fas fa-download"></i>
              </span>
              Download Cleaned Data
            </button>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-two-thirds">
            <h3 class="title">
              Data Cleaning
            </h3>
            <p class="subtitle">Use the tools at the top of each column to clean your data. You may need to scroll horizontally if you have many columns.</p>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-two-thirds is-flex is-justify-content-center">
            <div class="field is-grouped is-grouped-multiline">
              <div class="control">
                <div class="tags has-addons">
                  <div class="tag is-dark">Columns</div>
                  <div class="tag is-primary">{data.size}</div>
                </div>
              </div>
              <div class="control">
                <div class="tags has-addons">
                  <div class="tag is-dark">Rows</div>
                  <div class="tag is-primary">{map2rows(data).length}</div>
                </div>
              </div>
            </div>
          </div>

        </div>
        {isSending ? <progress class="progress is-primary" max="100">Loading...</progress> : null}
        <div class="table-container" style={{ transform: "rotateX(180deg)", "overflow-x": "auto" }}>
          <table class="table" style={{ transform: "rotateX(180deg)" }}>
            <thead>
              <tr>
                {Array.from(data, ([key, value]) =>
                  <th>
                    <div class="buttons is-centered">
                      <button class="button is-link" disabled={isSending} onClick={() => splitName(key)}>Split Name</button>
                      <button class="button is-link" disabled={isSending} onClick={() => cleanName(key)}>Clean Name</button>
                      <input class="input" type="text" placeholder="Remove rows with..." onKeyDown={e => filterRows(e, key)} onInput={e => setFilterInput(e.target.value)} />
                    </div>
                    <div class="columns">
                      <div class="column">
                        {key}
                      </div>
                    </div>
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {map2rows(data).map(row =>
                <tr>
                  {row.map(cell =>
                    <td>
                      {cell}
                    </td>
                  )}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div >
  );
};
