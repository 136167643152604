import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css'
import 'bulma/css/bulma.min.css';
import SanitizeCSV from './SanitizeCSVApp';

function App() {
  return (
    <div className="App">
      <section class="hero">
        <div class="hero-body">
          <h1 class="title">Simple.ML - Ready to Use AI Powered Applications</h1>
          <p class="subtitle">
            This is a placeholder page that contains example Simple.ML applications.
          </p>
        </div>
      </section>
      <section class="section">
        <h2 class="title">Marketing List Sanitization</h2>
        <p class="subtitle">
          Clean you marketing lists by uploading a CSV of contacts. Includes name sanitization and filtering.
        </p>
        <SanitizeCSV />
      </section>
    </div>
  );
}

export default App;
